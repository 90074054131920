import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import BaseLink from 'components/links/BaseLink'
import FontIcon from 'components/base/FontIcon'
import Spinner from 'components/loaders/Spinner'

import rgba from 'lib/rgba'
import { getProductTheme } from 'lib/CheckProduct'
import { makeRoundedCorners, transitionSimple } from 'styles/mixins'

const buttonSizes = {
  big: 70,
  fill: 70,
  large: 50,
  medium: 40,
  small: 30
}

const iconSizeMappings = {
  big: 'normal',
  fill: 'normal',
  large: 'normal',
  medium: 'medium',
  small: 'small'
}

const useStyles = createUseStyles(({ colors, typography }) => ({
  button: props => ({
    ...makeRoundedCorners(props.size === 'small' ? 8 : 12),
    ...transitionSimple(),

    alignItems: 'center',
    border: !props.includeBorder && 0,
    display: 'flex',
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.semibold,
    justifyContent: 'center',
    letterSpacing: 1.2,
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    textTransform: 'uppercase',

    '&:hover:not(:disabled)': {
      cursor: 'pointer'
    },

    '&::before': {
      ...transitionSimple(),

      background: '#fff',
      bottom: 0,
      content: '""',
      display: 'block',
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0
    }
  }),

  button_disabled: {
    opacity: 0.5
  },

  // Sizes
  button_bigSize: {
    ...makeRoundedCorners(16),

    height: buttonSizes.big,
    lineHeight: '68px',
    minWidth: 180,
    paddingLeft: 35,
    paddingRight: 35
  },
  button_fillSize: {
    height: buttonSizes.big,
    lineHeight: '68px',
    minWidth: '100%',
    paddingLeft: 25,
    paddingRight: 25
  },
  button_largeSize: {
    height: buttonSizes.large,
    lineHeight: '48px',
    minWidth: 120,
    paddingLeft: 25,
    paddingRight: 25
  },
  button_mediumSize: {
    height: buttonSizes.medium,
    lineHeight: '38px',
    minWidth: 100,
    paddingLeft: 20,
    paddingRight: 20
  },
  button_smallSize: {
    height: buttonSizes.small,
    lineHeight: '28px',
    paddingLeft: 15,
    paddingRight: 15
  },

  // Colors & Variants
  ...Object.keys(colors).reduce(
    (colorStyles, color) => ({
      ...colorStyles,

      [`button_contained_${color}`]: {
        backgroundColor: colors[color],
        color: colors.light,

        '&:hover:not(:disabled), &:focus:not(:disabled)': {
          boxShadow: `0px 8px 15px ${rgba(colors[color], 0.23)}`
        }
      },

      [`button_bodered_${color}`]: {
        backgroundColor: 'transparent',
        borderColor: colors[color],
        borderStyle: 'solid',
        borderWidth: 1,
        color: colors[color]
      },

      [`button_outline_${color}`]: {
        backgroundColor: 'transparent',
        borderColor: colors[color],
        borderStyle: 'solid',
        borderWidth: 2,
        color: colors[color],

        '&:hover:not(:disabled), &:focus:not(:disabled)': {
          backgroundColor:
            color === 'negative' ? colors.negative : colors.light,
          borderColor: color === 'negative' ? colors.negative : colors.light,
          color: () => {
            if (color === 'negative') return colors.light
            if (color === 'light') return colors.primary

            return colors[color]
          },
          boxShadow: `0px 8px 15px ${rgba(colors[color], 0.23)}`
        }
      }
    }),
    {}
  ),

  // Icon
  button_iconOnly: {
    minWidth: ({ size }) => buttonSizes[size],
    paddingLeft: 0,
    paddingRight: 0,
    width: ({ size }) => buttonSizes[size],

    '& $icon': {
      marginRight: 0
    }
  },
  icon: {
    marginRight: 10,
    color: props => (props.includeBorder ? colors[props.color] : 'light')
  }
}))

function Button({
  color,
  disabled,
  icon,
  label,
  size,
  type,
  variant,
  includeBorder,
  isLoading,
  ...other
}) {
  const classes = useStyles({
    size,
    includeBorder,
    color
  })

  return (
    <BaseLink
      disabled={disabled}
      defaultTag="button"
      defaultTagProps={{ type }}
      className={classNames(
        classes.button,
        classes[`button_${size}Size`],
        classes[`button_${variant}_${color}`],
        {
          [classes.button_iconOnly]: icon && !label,
          [classes.button_disabled]: disabled,
          [classes.button_link]: other.to || other.href
        }
      )}
      {...other}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        icon && (
          <FontIcon
            color={variant !== 'outline' && 'light'}
            className={classes.icon}
            name={icon}
            size={
              variant === 'bodered'
                ? iconSizeMappings.medium
                : iconSizeMappings[size]
            }
          />
        )
      )}
      {!isLoading && label}
    </BaseLink>
  )
}

Button.propTypes = {
  color: PropTypes.oneOf(Object.keys(getProductTheme().colors)),
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(buttonSizes)),
  type: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outline', 'bodered']),
  includeBorder: PropTypes.bool,
  isLoading: PropTypes.bool
}

Button.defaultProps = {
  color: 'tertiary',
  disabled: false,
  icon: null,
  label: null,
  size: 'big',
  type: 'submit',
  variant: 'contained',
  includeBorder: false,
  isLoading: false
}

export default Button
