import { gql } from '@apollo/client'
import { snakeCase, startCase } from 'lodash'
import useQuery from 'hooks/useQuery'
import usePermissions, { permissions } from 'hooks/usePermissions'

export const LicenseTransaction = {
  fragments: {
    details: gql`
      fragment LicenseTransaction_details on LicenseTransaction {
        id
        available
        category
        used
        totalLicenses
        solution {
          id
          type
        }
      }
    `
  }
}

export const License = {
  fragments: {
    details: gql`
      fragment License_details on License {
        id
        licenseTransaction {
          ...LicenseTransaction_details
        }
        account {
          id
          role {
            name
          }
        }
      }

      ${LicenseTransaction.fragments.details}
    `
  }
}

export const LICENSE_TRANSACTIONS_QUERY = gql`
  query LicenseTransactionsQuery {
    licenseTransactions {
      ...LicenseTransaction_details
    }
  }

  ${LicenseTransaction.fragments.details}
`

export const ENQUIRE_SOLUTION_MUTATION = gql`
  mutation EnquireSolutionMutation($input: EnquireSolutionInput!) {
    enquireSolution(input: $input) {
      id
    }
  }
`

export const REQUEST_LICENSE_MUTATION = gql`
  mutation RequestLicenseMutation($input: RequestLicenseInput!) {
    requestLicense(input: $input) {
      id
    }
  }
`

export const LICENSE_TRANSACTION_CATEGORIES_QUERY = gql`
  query LicenseTransactionCategoriesQuery {
    licenseTransactionCategories {
      categories
    }
  }
`

export const CREATE_LICENSE_MUTATION = gql`
  mutation createLicenseMutation($input: CreateLicenseInput!) {
    createLicense(input: $input) {
      ...License_details
    }
  }

  ${License.fragments.details}
`

export const EXPIRE_LICENSE_MUTATION = gql`
  mutation ExpireLicenseMutation($id: ID!) {
    expireLicense(id: $id) {
      id
    }
  }
`

const sortSolutionsByType = solutionsList => {
  const solutionsSortOrder = [
    'OnCall',
    'OnCallCalendar',
    'Enterprise',
    'OpNote',
    'SurgeryWorkflow',
    'IntegrationHub',
    'Voice'
  ]

  return [...solutionsList].sort(
    (a, b) =>
      solutionsSortOrder.indexOf(a?.type) - solutionsSortOrder.indexOf(b?.type)
  )
}

const sortLicenseByCategory = licenseCategory => {
  const sortOrder = ['provider', 'staff', 'facility']

  licenseCategory.sort(
    (a, b) => sortOrder.indexOf(a.category) - sortOrder.indexOf(b.category)
  )

  return licenseCategory
}

const formatLicenseTransactionBySolution = lincenseTypeData => {
  const sortedData = sortLicenseByCategory(lincenseTypeData)

  return sortedData.map(item => ({
    id: item.id,
    category: item.category,
    used: item.used,
    available: item.available,
    total: item.totalLicenses
  }))
}

export const formatLicenseTransactionsData = (
  licenseTransactionsData,
  solutionList
) =>
  sortSolutionsByType(solutionList).map(solution => ({
    solutionId: solution.id,
    icon: snakeCase(solution.type),
    title: startCase(solution.type),
    data: formatLicenseTransactionBySolution(
      licenseTransactionsData.filter(
        licenseTransaction => licenseTransaction?.solution?.id === solution.id
      )
    )
  }))

function useLicenseTransactionCategories() {
  const canViewLicenseTransactionCategories = usePermissions(
    permissions.VIEW_LICENSE_TRANSACTION_CATEGORIES
  )

  const { data: { licenseTransactionCategories = {} } = {} } = useQuery(
    LICENSE_TRANSACTION_CATEGORIES_QUERY,
    {
      skip: !canViewLicenseTransactionCategories
    }
  )

  return licenseTransactionCategories.categories
}

export function useSelectLicenseTransactionCategories(solution) {
  let categories = useLicenseTransactionCategories()

  if (categories) {
    // eslint-disable-next-line no-unused-expressions
    solution === 'On Call Calendar'
      ? (categories = categories.filter(
          category => category.value === 'facility'
        ))
      : (categories = categories.filter(
          category => category.value !== 'facility'
        ))
  }

  return [categories]
}

export function sanitizeLicenseData(licenseTransactions, solutions, licenses) {
  const formattedLicenseTransactionsData = formatLicenseTransactionsData(
    licenseTransactions,
    solutions
  ).filter(lt => lt.data.length)

  let sanitizedLicenseTransactions = formattedLicenseTransactionsData.map(
    licenseTransaction => ({
      ...licenseTransaction,
      data: licenseTransaction.data.reduce(
        (acc, licenseType) => ({
          ...acc,
          [licenseType.category]: licenseType.available || 0
        }),
        {}
      )
    })
  )

  if (!licenses) return sanitizedLicenseTransactions

  const sanitizedLicenses = licenses.map(license => ({
    ...license,
    solutionId: license.licenseTransaction.solution.id
  }))

  sanitizedLicenseTransactions = sanitizedLicenseTransactions.map(
    licenseTransaction => {
      const { solutionId: ltSolutionId } = licenseTransaction
      const license = sanitizedLicenses.find(
        ({ solutionId }) => solutionId === ltSolutionId
      )

      if (license) {
        return {
          ...licenseTransaction,
          license
        }
      }

      return licenseTransaction
    }
  )

  return sanitizedLicenseTransactions
}

export default {
  LICENSE_TRANSACTIONS_QUERY,
  REQUEST_LICENSE_MUTATION,
  CREATE_LICENSE_MUTATION,
  EXPIRE_LICENSE_MUTATION,
  formatLicenseTransactionsData,
  useSelectLicenseTransactionCategories
}
